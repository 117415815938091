import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Product from '../views/Product.vue'
import Basket from '../views/Basket.vue'
import Contact from '../views/Contact.vue'
import Article from '../views/Article.vue'
import Payment from '../views/Payment.vue'
import About from '../views/About.vue'
import NotFound from '../views/NotFound.vue'

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/product/:id',
        name: 'Product',
        component: Product,
        meta: { title: 'مشاهده محصول' }

    },
    {
        path: '/basket',
        name: 'Basket',
        component: Basket,
        meta: { title: 'سبد خرید' }
    },
    {
        path: '/about',
        name: 'About',
        component: About,
        meta: { title: 'دربارۀ ما' }

    },
    {
        path: '/payment',
        name: 'Payment',
        component: Payment,
        meta: { title: 'پرداخت آنلاین' }

    }, {
        path: '/article',
        name: 'Article',
        component: Article,
        meta: { title: 'دانستنی ها' }

    }, {
        path: '/contact-us',
        name: 'Contact',
        component: Contact,
        meta: { title: 'تماس با ما' }

    }, {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: "is-active", // active class for non-exact links.
    linkExactActiveClass: "is-exactive", // active class for *exact* links
})

router.beforeEach((to, from, next) => {
    let documentTitle = `${process.env.VUE_APP_TITLE}`;
    if (to.meta.title) {
        documentTitle = `${to.meta.title} - ` + documentTitle;
    }
    document.title = documentTitle;
    next()
});

export default router