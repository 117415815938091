<template>
  <!--   <div
       class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 py-5"

  >
    <div v-for="product in products" :key="product.id"   class="
        flex flex-col
   
bg-blue-200        shadow
        rounded-lg
      ">
      <div class="rounded-lg overflow-hidden">
        <div class="relative">
          <img class="h-full w-full" :src="product.image" :alt="product.name" />
        </div>
        <div class="relative ">
          <div class="py-10 px-8">
            <h3 class="text-xl font-bold">{{ product.name }}</h3>
            <div class="text-gray-600 text-sm font-medium flex mb-4 mt-2">
              {{ product.price * 1000 }} تومان
            </div>
            <p class="leading-7">
              {{ product.caption }}
            </p>
            <div class="mt-10 flex justify-between items-center">
              <div>
                <img
                  src="https://collect.criggzdesign.com/wp-content/uploads/2020/07/Ps.svg"
                  alt=""
                  class="w-6"
                />
              </div>
              <a
                href="https://collect.criggzdesign.com/index.php/resources/mockups/notepad/"
                class="flex items-center"
              >
                <p class="ml-4">مشاهده توضیحات</p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14.125"
                  height="13.358"
                  viewBox="0 0 14.125 13.358"
                >
                  <g transform="translate(-3 -3.293)">
                    <path
                      id="Path_7"
                      data-name="Path 7"
                      d="M14.189,10.739H3V9.2H14.189L9.361,4.378l1.085-1.085,6.679,6.679-6.679,6.679L9.361,15.566Z"
                      fill="#1d1d1d"
                      fill-rule="evenodd"
                    ></path>
                  </g>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <div
    class="
      grid grid-cols-1
      sm:grid-cols-2
      lg:grid-cols-3
      2xl:grid-cols-4
      gap-4
      py-5
    "
  >
    <div
      v-for="product in products"
      :key="product.id"
      class="group
        flex flex-col
        items-center
        bg-white
        p-4
        border
        rounded-lg
        hover:shadow-lg
        hover:border-gray-300
        duration-100
        dark:bg-gray-600
        dark:text-gray-400
        dark:border-gray-700
      "
    >
      <div
        class="
          inline-flex
          shadow-lg
          border border-gray-200
          dark:border-gray-900
          rounded-full
          overflow-hidden
          h-44   group-hover:scale-200
          w-44
        "
      >
        <img :src="product.image" :alt="product.name" class="h-full w-full transform duration-200" />
      </div>

      <h2 class="mt-4 font-bold text-xl">{{ product.name }}</h2>
      <h6 class="mt-2 text-sm font-medium text-indigo-600 dark:text-indigo-300">
        {{ product.price }} هزار تومان
      </h6>

      <p v-html="product.caption" class="text-xs text-gray-500 text-center mt-3 dark:text-gray-400"></p>

      <ul class="flex flex-row mt-4 text-lg">
        <li>
          <router-link
            href=""
            class="
              flex
              items-center
              justify-center
              h-10
              w-10
              border
              rounded-full
              text-gray-800
              border-gray-800
              hover:bg-black
              hover:text-white
              duration-300
            "
            :to="{'name':'Product','params':{id:product.id}}"
          >
            <i class="fa fa-eye"></i>
          </router-link>
        </li>
        <li>
          <a
            href=""
            class="
              flex
              items-center
              justify-center
              h-10
              w-10
              border
              rounded-full
              text-gray-800
              border-gray-800
              hover:bg-black
              hover:text-white
              duration-300
              mr-2
            "
          >
            <i class="fa fa-cart-plus"></i>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import products from "@/data/items.js";

export default {
  name: "Home",
  data() {
    return {
      products: products,
    };
  },
  components: {},
};
</script>
