<template>
  <div class="sidebar">
    <!-- Side Nav Bar-->

    <div class="sidebar-brand">
      <!-- Logo Section -->
      <img src="@/assets/logo.png" alt="کوک موک" />
    </div>

    <ul class="sidebar-menu">
      <!-- Items Section -->
      <li class="sidebar-item">
        <router-link :to="{ name: 'Home' }" class="sidebar-link"
          ><i class="fa fa-home sidebar-icon"></i>خانه</router-link
        >
      </li>
      <li class="sidebar-item">
        <router-link :to="{ name: 'Basket' }" class="sidebar-link"
          ><i class="fa fa-shopping-cart sidebar-icon"></i>سبد خرید</router-link
        >
      </li>     <li class="sidebar-item">
        <router-link :to="{ name: 'Article' }" class="sidebar-link"
          ><i class="fa fa-book sidebar-icon"></i>دانستنی</router-link
        >
      </li>    <li class="sidebar-item">
        <router-link :to="{ name: 'Payment' }" class="sidebar-link"
          ><i class="fa fa-credit-card sidebar-icon"></i>پرداخت آنلاین</router-link
        >
      </li> <li class="sidebar-item">
        <router-link :to="{ name: 'About' }" class="sidebar-link"
          ><i class="fa fa-info sidebar-icon"></i>دربارۀ ما</router-link
        >
      </li>   
   
      <li class="sidebar-item">
        <router-link :to="{ name: 'Contact' }" class="sidebar-link"
          ><i class="fa fa-phone sidebar-icon"></i>تماس</router-link
        >
      </li>
    </ul>

    <div class="mt-auto flex items-center w-full" dir="ltr">
      <a target="_blank"
        href="http://instagram.com/kookmook.ir"
        class="
          h-16
          mx-auto
          flex
          justify-center
          items-center
          focus:text-orange-500
          focus:outline-none
          hover:text-blue-500 text-xl
        "
      >
        <i class="fab fa-instagram"></i>
        </a
      > <a 
        href="tg://resolve?domain=kookmook_ir"
        class="
          h-16
          mx-auto
          flex
          justify-center
          items-center
          focus:text-orange-500
          focus:outline-none
          hover:text-blue-500 text-xl
        "
      >
        <i class="fab fa-telegram"></i>
        </a
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="postcss">
.sidebar {
  @apply flex flex-col px-4 items-center h-full;
}
.sidebar-brand {
  @apply flex flex-col items-center w-full  pt-2;
}
.sidebar-brand img {
  @apply max-h-16 flex;
}
.sidebar-menu {
  @apply w-full;
}
.sidebar-item {
  @apply block pt-1;
}
.sidebar-link {
  @apply relative duration-150 block p-2 text-right 
                text-sm text-gray-600
                dark:text-gray-400
                hover:text-gray-700
                dark:hover:text-gray-100
                hover:bg-gray-100
                dark:hover:bg-gray-800
                rounded;
}
.sidebar-icon {
  @apply  text-gray-400 w-7 text-center
}
.sidebar-link.is-active {
  @apply bg-gray-200
                dark:bg-gray-800;
}
</style>

