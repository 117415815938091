<template>
  <button @click="dropdownOpen = !dropdownOpen">New</button>
  {{ dropdownOpen }}
  <div class="relative  w-full border-8">
    <transition
    
      enter-active-class="duration-300 ease-out opacity-0"
           enter-to-class="opacity-100"
           leave-active-class="duration-200 ease-in"
           leave-class="opacity-100"
           leave-to-class="opacity-0"
    
    
    >
      <div v-show="dropdownOpen" class="border-2 bg-white p-2 absolute top-0 left-0">
        <span class="block border p-2 m-1 rounded">one</span>
        <span class="block border p-2 m-1 rounded">two</span>
        <span class="block border p-2 m-1 rounded">tree</span>
        <span class="block border p-2 m-1 rounded">four</span>
      </div>
    </transition>
  </div>
  <hr>
  <transition
  enter-active-class="transition-opacity duration-75"
  enter-from-class="opacity-0"
  enter-to-class="opacity-100"
  leave-active-class="transition-opacity duration-150"
  leave-from-class="opacity-100"
  leave-to-class="opacity-0"
>
  <div v-show="dropdownOpen">
    <!-- Will fade in and out -->
    <p>adfasdfsd</p>
  </div>
</transition>

<hr/>
   <Transition
        v-if="dropdownOpen"
        enter="transition ease-out duration-75"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-150"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        className="absolute left-0 w-56 mt-2 origin-top-left rounded-md shadow-lg z-10"
      >
        <div className="bg-white rounded-md shadow-xs">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <a
              href="https://github.com/tailwindlabs/tailwindui-react"
              rel="noopener noreferrer"
              className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
              role="menuitem"
            >
              GitHub
            </a>
            <a
              href="https://www.npmjs.com/package/@tailwindui/react"
              rel="noopener noreferrer"
              className="block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
              role="menuitem"
            >
              NPM
            </a>
          </div>
        </div>
      </Transition>

  <hr/>
      <div class="relative">
                <button
                  @click="dropdownOpen = !dropdownOpen"
                  class="
                    flex
                    items-center
                    space-x-2
                    relative
                    focus:outline-none
                  "
                >
                  <h2
                    class="
                      text-gray-700
                      dark:text-gray-300
                      text-sm
                      hidden
                      sm:block
                    "
                  >
                    Jones Ferdinand
                  </h2>
                  <img
                    class="
                      h-9
                      w-9
                      rounded-full
                      border-2 border-purple-500
                      object-cover
                    "
                    src="../assets/pwa.png"
                    alt="Your avatar"
                  />
                </button>

                <div
                  class="
                    absolute
                    right-0
                    mt-2
                    w-48
                    bg-white
                    rounded-md
                    overflow-hidden
                    shadow-xl
                    z-10
                  "
                  v-show="dropdownOpen"
            
                  @click="dropdownOpen = false"
                >
                  <a
                    href="#"
                    class="
                      block
                      px-4
                      py-2
                      text-sm text-gray-700
                      hover:bg-purple-600
                      hover:text-white
                    "
                    >Profile</a
                  >
                  <a
                    href="#"
                    class="
                      block
                      px-4
                      py-2
                      text-sm text-gray-700
                      hover:bg-purple-600
                      hover:text-white
                    "
                    >Tickets</a
                  >
                  <a
                    href="/login"
                    class="
                      block
                      px-4
                      py-2
                      text-sm text-gray-700
                      hover:bg-purple-600
                      hover:text-white
                    "
                    >Logout</a
                  >
                </div>
              </div>
  <hr>

  <!-- Modal trigger -->
  <button
    @click="showModal = !showModal"
    class="
      bg-indigo-500
      hover:bg-indigo-600
      focus:outline-none
      rounded-md
      px-6
      py-3
      mx-auto
      block
      text-white
      transition
      duration-500
      ease-in-out
      mt-32
    "
  >
    Open modal
  </button>
  <!-- ./Modal trigger -->

  <div
    class="flex items-center justify-center absolute h-screen top-0 left-0"
  ></div>

  <transition
    name="custom"
    enter-active-class="animate__animated animate__bounceInDown"
    leave-active-class="animate__animated animate__bounceOutRight"
  >
    <!-- Modal -->
    <div
      v-if="showModal"
      class="
        w-11/12
        lg:w-full
        max-w-xl
        z-20
        mx-auto
        bg-white
        flex flex-col
        relative
        self-center
        shadow-2xl
        rounded-md
      "
    >
      <!-- Modal header -->
      <div
        class="p-6 border-b-4 border-gray-200 text-lg font-bold text-indigo-400"
      >
        Modal title
      </div>
      <!-- ./Modal header -->

      <!-- Modal body -->
      <div class="p-6">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident
        officiis ipsam cupiditate illum laborum atque voluptatibus ut doloremque
        excepturi quisquam repellendus dolor deserunt totam tempore, laboriosam
        earum sapiente esse praesentium.
      </div>
      <!-- ./Modal body -->

      <!-- Modal footer -->
      <div class="border-t-4 border-gray-200 p-6 flex justify-end">
        <button
          @click="showModal = false"
          class="
            bg-green-400
            hover:bg-green-500
            focus:outline-none
            transition
            px-4
            py-2
            rounded-md
            text-white
            duration-500
            ease-in-out
          "
        >
          Close Modal
        </button>
      </div>
      <!-- ./Modal footer -->
    </div>
    <!-- ./Modal -->
  </transition>

  <transition
    name="custom"
    enter-active-class="animate__animated animate__fadeIn"
    leave-active-class="animate__animated animate__bounceOutLeft"
  >
    <!-- Overlay -->
    <div
      v-if="showModal"
      @click="showModal = false"
      class="
        bg-gray-900 bg-opacity-50
        fixed
        bottom-0
        left-0
        w-full
        h-full
        transition
        duration-500
        ease-in-out
        transfom
        z-10
      "
    ></div>
    <!-- ./Overlay -->
  </transition>
</template>

<script>
export default {
  data() {
    return {
      showModal: false,
      DropDown: true,
      dropdownOpen:true
    };
  },
};
</script>

<style >
/* @import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"); */
</style>