<template>
  <div class="dark:text-gray-400">
    <section class="text-gray-700 body-font overflow-hidden bg-white">
      <div class="container px-5 py-24 mx-auto">
        <div class="lg:w-4/5 mx-auto flex flex-wrap">
          <div class="lg:w-1/2 w-full">
            <img
              alt="ecommerce"
              class="
                w-full
                object-cover object-center
                rounded
                border border-gray-200
              "
              :src="product.image"
            />
            <img
              alt="ecommerce"
              class="
                w-1/3
                m-2
                object-cover object-center
                rounded
                border border-gray-200
              "
              :src="product.image"
            />
          </div>
          <div class="lg:w-1/2 w-full lg:pr-10 lg:py-6 mt-6 lg:mt-0">
            <h2 class="text-sm title-font text-gray-500 tracking-widest">
              BRAND NAME
            </h2>
            <h1 class="text-gray-900 text-3xl title-font font-medium mb-1">
              {{ product.name }}
            </h1>

            <div class="flex mb-4">
              <span class="flex ml-3 pl-3 py-2 border-l-2 border-gray-200">
                {{ product.code }}
              </span>
              <span class="flex items-center">
                <svg
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="w-4 h-4 text-red-500"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"
                  ></path>
                </svg>
                <svg
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="w-4 h-4 text-red-500"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"
                  ></path>
                </svg>
                <svg
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="w-4 h-4 text-red-500"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"
                  ></path>
                </svg>
                <svg
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="w-4 h-4 text-red-500"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"
                  ></path>
                </svg>
                <svg
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="w-4 h-4 text-red-500"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12 2l3.09 6.26L22 9.27l-5 4.87 1.18 6.88L12 17.77l-6.18 3.25L7 14.14 2 9.27l6.91-1.01L12 2z"
                  ></path>
                </svg>
                <span class="text-gray-600 mr-3">4 نظر</span>
              </span>
            </div>
            <p class="leading-relaxed" v-html="product.caption"></p>
            <div
              class="
                flex
                mt-6
                items-center
                pb-5
                border-b-2 border-gray-200
                mb-5
              "
            >
              <div class="flex">
                <span class="ml-3">رنگ</span>
                <button
                  class="
                    border-2 border-gray-300
                    rounded-full
                    w-6
                    h-6
                    focus:outline-none
                  "
                ></button>
                <button
                  class="
                    border-2 border-gray-300
                    mr-1
                    bg-gray-700
                    rounded-full
                    w-6
                    h-6
                    focus:outline-none
                  "
                ></button>
                <button
                  class="
                    border-2 border-gray-300
                    mr-1
                    bg-red-500
                    rounded-full
                    w-6
                    h-6
                    focus:outline-none
                  "
                ></button>
              </div>
              <div class="flex mr-6 items-center">
                <span class="ml-3">اندازه</span>
                <div class="relative">
                  <select
                    class="
                      rounded
                      border
                      appearance-none
                      border-gray-400
                      py-2
                      focus:outline-none
                      focus:border-red-500
                      text-base
                      pr-3
                      pl-10
                    "
                  >
                    <option>SM</option>
                    <option>M</option>
                    <option>L</option>
                    <option>XL</option>
                  </select>
                  <span
                    class="
                      absolute
                      left-0
                      top-0
                      h-full
                      w-10
                      text-center text-gray-600
                      pointer-events-none
                      flex
                      items-center
                      justify-center
                    "
                  >
                    <svg
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      class="w-4 h-4"
                      viewBox="0 0 24 24"
                    >
                      <path d="M6 9l6 6 6-6"></path>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <div class="flex">
              <span class="title-font font-medium text-2xl text-gray-900"
                >{{ product.price }} هزار تومان</span
              >
              <button
                class="
                  flex
                  mr-auto
                  text-white
                  bg-red-500
                  border-0
                  py-2
                  px-6
                  focus:outline-none
                  hover:bg-red-600
                  rounded
                "
              >
                Button
              </button>
              <button
                class="
                  rounded-full
                  w-10
                  h-10
                  bg-gray-200
                  p-0
                  border-0
                  inline-flex
                  items-center
                  justify-center
                  text-gray-500
                  mr-4
                "
              >
                <svg
                  fill="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  class="w-5 h-5"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M20.84 4.61a5.5 5.5 0 00-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 00-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 000-7.78z"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <div id="pAparat" class="h_iframe-aparat_embed_frame">
    <span style="display: block; padding-top: 57%"></span>
    <iframe
      src="https://www.aparat.com/video/video/embed/videohash/8TF7R/vt/frame"
      allowfullscreen="true"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
    ></iframe>
  </div>
</template>


<script>
import products from "@/data/items.js";

export default {
  name: "Home",
  data() {
    return {
      product: products[0],
    };
  },
  components: {},
};
</script>

<style lang="postcss" scoped>
.h_iframe-aparat_embed_frame {
  position: relative;
}

.h_iframe-aparat_embed_frame .ratio {
  display: block;
  width: 100%;
  height: auto;
}

.h_iframe-aparat_embed_frame iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>