<template>
  <div class="h-screen w-screen bg-blue-600 flex justify-center content-center items-center">
      <div  class="text-white text-center">
    <p class="text-9xl">404</p>
    <div>صفحه مورد نظر پیدا نشد</div></div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>