<template>
  <div class="about">
    <h1>دانستنی</h1>

    <div class="bg-gray-100">
      <!-- featured -->
      <div class="max-w-5xl mx-auto py-10">
        <ul class="flex flex-wrap -mx-2 overflow-hidden">
          <li
            class="my-2 px-2 w-full overflow-hidden md:w-1/3 lg:w-1/3 xl:w-1/3"
          >
            <a href="">
              <div
                class="
                  mx-2
                  flex
                  items-center
                  justify-center
                  bg-gray-300 bg-cover bg-center
                  relative
                  rounded-lg
                  overflow-hidden
                "
                style="
                  height: 400px;
                  background-image: url(http://lorempixel.com/300/400/nature/);
                "
              >
                <div
                  class="absolute w-full h-full bg-black z-10 opacity-50"
                ></div>
                <div class="relative z-20 text-center p-5">
                  <span
                    class="
                      inline-block
                      text-white
                      uppercase
                      text-xs
                      tracking-wide
                    "
                    >Nature</span
                  >
                  <h2 class="text-white font-semibold font-serif text-xl my-5">
                    Spend time with nature to recharge yourself
                  </h2>
                  <span class="inline-block text-xs text-white font-sans"
                    >December 02, 2019. By Debbie</span
                  >
                </div>
              </div>
            </a>
          </li>

          <li
            class="my-2 px-2 w-full overflow-hidden md:w-1/3 lg:w-1/3 xl:w-1/3"
          >
            <a href="">
              <div
                class="
                  mx-2
                  flex
                  items-center
                  justify-center
                  bg-gray-300 bg-cover bg-center
                  relative
                  rounded-lg
                  overflow-hidden
                "
                style="
                  height: 400px;
                  background-image: url(http://lorempixel.com/300/400/food/);
                "
              >
                <div
                  class="absolute w-full h-full bg-white z-10 opacity-50"
                ></div>
                <div class="relative z-20 text-center p-5">
                  <span
                    class="
                      inline-block
                      text-black
                      uppercase
                      text-xs
                      tracking-wide
                    "
                    >Nature</span
                  >
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="mt-20">
      <h2 class="font-light text-xl mb-5 text-center">Search the blog</h2>
      <div class="relative border rounded-sm overflow-hidden">
        <form action="">
          <input
            class="w-full relative p-5 font-light text-gray-900 border-0"
            type="text"
            name="s"
            id=""
            placeholder="Search..."
          />
          <button
            type="submit"
            class="bg-transparent border-0 absolute top-auto right-0 px-5 py-5"
            style="top: 2px"
          >
            <span class="block w-5">
              <svg
                class="fill-current"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                ...
              </svg>
            </span>
          </button>
        </form>
      </div>
    </div>

    <button
      @click="dropdownOpen = !dropdownOpen"
      class="p-2 rounded bg-blue-100 hover:bg-blue-300 duration-1000"
    >
      ++
    </button>
    <hr />
    <p>{{ dropdownOpen }}</p>
    <transition
      enter-active-class="duration-1000"
      enter-to-class="bg-blue-600"
      leave-to-class="bg-green-600"
      leave-active-class="duration-1000"
    >
      <h1 class="text-5xl" v-show="dropdownOpen">transition</h1>
    </transition>

    <div class="relative h-40 bg-green-200">
      <img
        class="absolute object-center"
        src="https://randomuser.me/api/portraits/women/4.jpg"
      />
    </div>

    <div class="relative h-40">
      <img
        class="h-full w-full object-cover"
        src="https://randomuser.me/api/portraits/women/5.jpg"
      />
    </div>
    <div class="flex flex-wrap">
      <div
        v-for="(n, index) in 99"
        :key="index"
        class="rounded-full relative border-2 overflow-hidden"
      >
        <span
          class="
            text-4xl
            h-full
            w-full
            absolute
            object-center
            bg-green-200
            opacity-25
          "
          >{{ n }}</span
        >
        <img
          :src="newimage(n)"
          alt=""
          class="transform hover:scale-110 duration-200"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dropdownOpen: true,
    };
  },
  methods: {
    newimage(i) {
      return "https://randomuser.me/api/portraits/women/" + i + ".jpg";
    },
  },
};
</script>
