<template>
  <div class="about">
    <h1>تماس با ما</h1>
    <button @click="show = !show">Toggle</button>
    <transition name="fade">
      <p v-show="show">Hello</p>
    </transition>
    <hr />
    <hr />
    <hr />
    <hr />
    <hr />
    <hr />
    <hr />
    <hr />
    <hr />
    <hr />
    <hr />
    <hr />
    <hr />
    <hr />
    <hr />
    <hr />
    <hr />
    <hr />
    <hr />
    <hr />
    <hr />
    <hr />
    <hr />
    <hr />
    <hr />
    <hr />
    <hr />
    <hr />
    <hr />
    <hr />
    <hr />
    <hr />
    <hr />
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
    };
  },
};
</script>

<style lang="postcss">
.fade {
  @apply bg-yellow-200;
}
.fade-enter-active,
.fade-leave-active {
  @apply transition ease-out duration-1000 bg-white;
}

.fade-enter-from,
.fade-leave-to {
  @apply transform opacity-0 scale-95;
}
hr {
  @apply p-7;
}
</style>