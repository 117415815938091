const products = [{
        id: 1,
        name: "متر جمع شو آویزدار",
        code: "o-55",
        price: 68,
        caption: "ساخت آلمان، همراه با آویز",
        image: "https://kookmook.ir/images/upload/Pro_1583330928.jpg",
        update: "2021/09/04 14:32",

    },
    {
        id: 2,
        name: "ماکو چرخ خیاطی ژانومه 802",
        code: "M-76",
        price: 45,
        caption: "انواع ماکو برای چرخ های ژانومه + برادر +مارشال + جک + سینگر",
        image: "https://kookmook.ir/images/upload/Pro_1587215473.jpg",
        update: "2021/09/04 14:34",

    }, {
        id: 3,
        name: "طیاره چوبی (ژانت)",
        code: "T-55",
        price: 95,
        caption: "برای اتو کشیدن قسمت هایی از کت ، شلوار و مانتو",
        image: "https://kookmook.ir/images/upload/Pro_1608551880.jpg",
        update: "2021/09/04 14:41",

    },
    {
        id: 4,
        name: "قیچی سرنخ زن انگشتی",
        code: "G-35",
        price: 20,
        caption: "-",
        image: "https://kookmook.ir/images/upload/pro_g35.jpg",
        update: "2021/09/04 14:42",

    },
    {
        id: 5,
        name: "ماژیک محو شونده پارچه Adger",
        code: "M-51",
        price: 15,
        caption: "کاربرد: بعد از کشیدن خط و استفاده از این ماژیک رنگش محو میشود. فوق العاده نرم و استفاده از آن راحت است.<br/>دارای ماندگاری 24 ساعته<br/>در دو رنگ بنفش و صورتی",
        image: "https://kookmook.ir/images/upload/Pro_1583330791.jpg",
        update: "2021/09/04 14:45",

    },
    {
        id: 6,
        name: "ماکو چرخ خیاطی قدیمی",
        code: "M-55",
        price: 40,
        caption: "ماکو چرخ های قدیمی مارشال، ژانومه، چرخ های سیاه قدیمی ساده دوز",
        image: "https://kookmook.ir/images/upload/Pro_1604216936.jpg",
        update: "2021/09/04 14:52",

    },
    {
        id: 7,
        name: "نخ سردوز ملودی 108 رنگ",
        code: "N-38",
        price: 260,
        caption: "3 بسته 36 تایی",
        image: "https://kookmook.ir/images/upload/Pro_1582996809.jpg",
        update: "2021/09/04 14:54",

    },
    {
        id: 8,
        name: "اتو سرد فلزی",
        code: "o-29",
        price: 140,
        caption: "برای خط گیری لباس و صاف درز ها",
        image: "https://kookmook.ir/images/upload/Pro_1582996209.jpg",
        update: "2021/09/04 14:56",

    }
]

export default products;